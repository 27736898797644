// ** React Imports
import { ReactNode } from 'react';

// ** Next Import
import Link from 'next/link';

// ** MUI Components
import { styled } from '@mui/material/styles';
import { VigoTypography } from 'src/@vigo/vigo-typography';
import Box, { BoxProps } from '@mui/material/Box';
import { Button } from '@mui/material';

// ** Layout Import
import BlankLayout from 'src/@core/layouts/BlankLayout';

// ** Styled Components
const BoxWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    width: '90vw'
  }
}));

const Img = styled('img')(({ theme }) => ({
  marginBottom: theme.spacing(10),
  [theme.breakpoints.down('lg')]: {
    height: 450,
    marginTop: theme.spacing(10)
  },
  [theme.breakpoints.down('md')]: {
    height: 400
  },
  [theme.breakpoints.up('lg')]: {
    marginTop: theme.spacing(13)
  }
}));

const Error404 = () => {
  return (
    <>
      <Box className="content-center">
        <Box sx={{ p: 5, display: 'flex', flexDirection: 'row', alignItems: 'center', textAlign: 'left' }}>
          <BoxWrapper>
            <VigoTypography value="Whoops" variant="h2" sx={{ fontWeight: 600 }} />
            <VigoTypography
              value="Error 404 - The page you’ve requested was not found"
              variant="h5"
              sx={{ fontWeight: 500, mb: 1, fontSize: '1.5rem !important' }}
            />
            <VigoTypography value="Try going back and retry the link again" variant="h6" />
            <Link passHref href="/" legacyBehavior>
              <Button variant="contained" component="a" sx={{ cursor: 'pointer', mt: 20, px: 5.5 }}>
                Back
              </Button>
            </Link>
          </BoxWrapper>
          <Img height="338" alt="error-illustration" src="/images/pages/404.svg" />
        </Box>
      </Box>
    </>
  );
};

Error404.getLayout = (page: ReactNode) => <BlankLayout>{page}</BlankLayout>;

export default Error404;
